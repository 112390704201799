












































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsInputPassword from '@/components/VsInputPassword/Index.vue'
import { subscribe } from '@/api/userapi/users'
import { getParameterByName, isInWhitelist } from '@/utils/queryString'
import { AppModule } from '@/store/modules/app'
import axios from 'axios'
import { get } from 'lodash'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VueRecaptcha from 'vue-recaptcha'
import { getCookie, setCookie } from '@/utils/cookies'

@Component({
    name: 'Registration',
    components: {
        VsInputPassword,
        VueRecaptcha,
    },
})
export default class extends Vue {
    // TODO: questa pagina non è whitelabel registrazione e attivazione non sono whitelabel
    // ci sono riferimenti a 4dem ed alla privacy di 4dem
    private loading = false
    private showCode = false
    private registrationStep = 'REGISTRATION'
    private signupData = {
        email: '',
        password: '',
        presentationCode: '',
        referrer: window.location.href,
        utmCampaign: getParameterByName('utm_campaign', window.location.href) || '',
        utmMedium: getParameterByName('utm_medium', window.location.href) || '',
        utmSource: getParameterByName('utm_source', window.location.href) || '',
    }

    private recaptchaCode = false

    $refs: any

    get siteUrl () {
        return AppModule.siteUrl
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get privacyUrl () {
        return this.siteUrl + '/informativa-privacy'
    }

    get recaptchaSitekey () {
        return AppModule.recaptchaSitekey
    }

    get redirectCookie () {
        return AppModule.redirectCookie
    }

    beforeMount () {
        const formScript = document.createElement('script')
        formScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
        formScript.setAttribute('type', 'text/javascript')
        formScript.setAttribute('async', '')
        formScript.setAttribute('defer', '')
        document.head.appendChild(formScript)

        this.setRedirectUrl()
    }

    private setRedirectUrl () {
        const redirectUrl = get(this.$route, 'query.redirect', getCookie(this.redirectCookie))
        if (isInWhitelist(redirectUrl)) {
            setCookie(this.redirectCookie, redirectUrl, 365)
        }
    }

    private setFocus () {
        if (typeof this.$refs.autofocus !== 'undefined') {
            this.$refs.autofocus.$children[0].$refs.input.focus()
        }
    }

    private async registerUser () {
        this.loading = true
        try {
            await subscribe(this.signupData)
            this.sendSignupGtmEvent('S1')
            this.$router.push({
                name: 'dashboardIndex',
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const message = get(e, 'response.data.message', [])
                const status = get(e, 'response.status', '')
                if (status === 400) {
                    if (message === 'UserAlreadyExists') {
                        this.$refs.registerUser.setErrors({ email: ['Indirizzo email già esistente oppure non valido'] })
                    }
                }
            }
            this.$root.$vsToast({
                heading: 'Errore durante la registrazione',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private openContact () {
        if (window.Beacon) {
            window.Beacon('open')
        } else {
            window.open(this.siteUrl + '/contatta-4dem/', '_blank')
        }
    }

    private setRecaptchaResponse (response: any) {
        this.recaptchaCode = response
        if (response) {
            this.registerUser()
        }
    }

    private resetRecaptchaResponse () {
        this.$refs.gRec.reset()
        this.recaptchaCode = false
    }

    private async verifyRecaptcha () {
        const valid = await this.$refs.registerUser.validate()
        if (!valid) {
            this.$root.$vsToast({
                heading: 'Ci sono errori, ricontrolla i dati',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        if (!this.recaptchaCode) {
            this.$refs.gRec.execute()
        } else {
            this.registerUser()
        }
    }

    private sendSignupGtmEvent (eventName: any) {
        if (!this.$gtm) {
            return
        }

        this.$gtm.trackEvent({
            event: eventName,
            Signup_Category: 'Signup',
            Signup_Action: eventName,
            Signup_Value: 200,
            Signup_Label: `Form Signup ${eventName}`,
        })
    }
}
